/* eslint-disable */
import InfiniteScroll from 'infinite-scroll';

export default {
  init() {

    // JavaScript to be fired on the home page

    let pageIndex = 1;
    const elem = '.news-archive .row';
    const hasPagination = document.querySelector('.next.page-numbers');
    if (hasPagination)
    {
      const infScroll = new InfiniteScroll(elem, {
        // options
        path: function(){
          const next_page = pageIndex + 1;
          const queryString = window.location.search || "";

          return `page/${next_page}${queryString}`
        },
        append: 'article',
        status: '.scroller-status',
        history: 'true',
        hideNav: '.pagination'

      });

      infScroll.on( 'load', function( body, path, items, response ) {
        hasPagination.href = path;
        pageIndex++;
      });

      const snapbackCache = SnapbackCache({
        bodySelector: elem,
        nextPageOffset: function(){
          return infScroll.pageIndex;
        }
      });

      $(elem).on("snapback-cache:loaded", function(e, cachedPage) {
        // sets the pager to page from the appropriate place
        console.log('onCacheLoaded',cachedPage.nextPageOffset )
        pageIndex = cachedPage.nextPageOffset;
      });

      jQuery(document).on("click", ".news-archive .row a", function (e) {
      snapbackCache.cachePage();
      });
    }else{

      $('.scroller-status').hide();

    }

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};

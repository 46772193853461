/* eslint-disable */
import InfiniteScroll from 'infinite-scroll';

export default {
  init() {
    // JavaScript to be fired on the home page

    /*
    //MANUAL AJAX (da completare con i filtri)
    const snapbackCache = SnapbackCache({
      bodySelector: "#loop-container"
    });

    jQuery(document).on("click", "#loop-container a", function (e) {
      snapbackCache.cachePage();
    });

    const ajaxPagination = new AjaxPagination();
    */

    let pageIndex = 1;
    const elem = '.archive-container .row';
    const hasPagination = document.querySelector('.next.page-numbers');


    if (hasPagination)
    {
      const infScroll = new InfiniteScroll(elem, {
        // options
        path: function(){
          const next_page = pageIndex + 1;
          const queryString = window.location.search || "";

          return `page/${next_page}${queryString}`
        },
        append: 'article.prodotto',
        status: '.scroller-status',
        history: 'true',
        hideNav: '.pagination',
        scrollThreshold: 400

      });

      infScroll.on( 'load', function( body, path, items, response ) {
        hasPagination.href = path;

        pageIndex++;
      });

      infScroll.on( 'request', function( path, fetchPromise ) {console.log(path)})


   }else{
   $('.scroller-status').hide();
 }


  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};

/* eslint-disable */
// core version + navigation, pagination modules:
import Swiper from 'swiper/bundle';


export default {
  init() {
    // JavaScript to be fired on the home page

    window.addEventListener('load', () => {

      const swiper = new Swiper('.swiper-container',{
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        autoplay: {
          delay: 5000,
        },
        speed:1000
      })

    }, false);

  },

  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};

// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import blocks from './routes/blocks';
import blog from './routes/blog';
import downloads from './routes/downloads';
import archive from './routes/archive';
import singleProdotto from './routes/single-prodotto';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  blocks,
  // blog page.
  blog,
  //Downloads
  downloads,
  // archive prodotto
  archive,
  // single prodotto
  singleProdotto,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

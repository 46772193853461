/* eslint-disable */
// core version + navigation, pagination modules:
import Swiper from 'swiper/bundle';
import ekkoLightbox from 'ekko-lightbox';

export default {
  init() {
    // JavaScript to be fired on the home page

    window.addEventListener('load', () => {

      const galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });

      const swiper = new Swiper('.gallery',{
        loop: true,
        thumbs: {
          swiper: galleryThumbs
        }
      })

    }, false);

    $(document).on('click', '[data-toggle="lightbox"]', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });

    $('[data-toggle="tooltip"]').tooltip()

  },

  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};

/* eslint-disable */
import InfiniteScroll from 'infinite-scroll';

export default {
  init() {


    // JavaScript to be fired on the downloads page
    const elem = document.querySelector('.downloads-container');
    const nextPage = document.querySelector('.next.page-numbers');

    if (nextPage)
    {
      const is = new InfiniteScroll(elem, {
        // options
        path: '.next.page-numbers',
        append: '.prodotto-download',
        status: '.scroller-status',
        history: 'false',
        hideNav: '.pagination'

      });
    }else{
      $('.scroller-status').hide();
    }

  },
  finalize() {
    // JavaScript to be fired on the downloads page, after the init JS
  },
};
